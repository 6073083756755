import React, { useReducer, useContext, useEffect } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { api } from '../../../services/api'
import ColorButton from '../../Buttons/ColorButton';
import DefaultModal from '../../Utils/DefaultModal';
import toast from 'react-hot-toast'
import { Box, Tooltip, TextField } from '@mui/material'
import validateData from './validateFiles';
import { externalComponent } from '../../AppRoutes';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { number2dayweek } from '../../Utils/dayjsptbrhelper';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const url = '/pedido_documento/'

const submit = async (data, getData, setLoader, handleClose) => {

    setLoader(true)
    let res = null

    if (data.id)
        res = await api.patch(`${url}${data.id}/`, data)
    else
        res = await api.post(url, data)
    if (res.status === 201 || res.status === 200) {
        toast.success(`Documento ${res.status === 201 ? 'adicionado' : 'editado'} com sucesso`)
        getData()
        handleClose()
        setLoader(false)
        return
    }
    toast.error(`Documento não pôde ser ${!data.id ? 'adicionado' : 'editado.'} Motivo: ${res.data}`)
    setLoader(false)
}

const loadRetrieve = async (id, setState, setLoader) => {
    setLoader(true)
    const res = await api.get(url + `${id}/`)
    if (res.status === 200) {
        setState({ ...res.data})
    }
    setLoader(false)
}

const getTitle = (data) => {
    if (data?.id)

        return 'Editar'
    return 'Adicionar'
}

const selectOptions = ["PI Mãe", "PI"]

const EditFiles = ( {data, handleClose, reloadData,orderId} ) => {

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        order:orderId,
        name:'',
        number: '',
        ets:'',
        type: '',
        id: data.id ? data.id : 0
    })

    const validData = validateData(state)

    useEffect(() => {
        if (data.id)
            loadRetrieve(data.id, setState, setLoader)
        else
            setState({ loaded: true })
    }, [setLoader, data.id])
    
    return(
        <>
        <DefaultModal
                title={
                    `${getTitle(state)} Documento`
                }
                handleClose={handleClose}
                content={
                    <>
                        <Box className='item-content-modal noborder' >

                            <Box className='item-profile-row'>
                                    <TextField
                                        id="name"
                                        type="text"
                                        label="Nome"
                                        value={state.name}
                                        onChange={(e) => { setState({ 'name': e.target.value }) }}
                                        fullWidth={false}
                                        size='small'
                                        inputProps={{
                                            maxLength: 50
                                        }}
                                    />

                                    <TextField
                                        id="number"
                                        type="text"
                                        label="Número"
                                        value={state.number}
                                        onChange={(e) => { setState({ 'number': e.target.value }) }}
                                        fullWidth={false}
                                        size='small'
                                        inputProps={{
                                        maxLength: 50
                                        }}
                                    />

                            </Box>

                            <Box className='item-profile-row' sx={{alignItems:'flex-end'}}>

                                <DatePicker 
                                    slotProps={{ textField: { size: 'small' } }} 
                                    label="ETS:"
                                    value={dayjs(state.ets)}  
                                    onChange={(e) => { setState({ 'ets': e.format('YYYY-MM-DD') }) }}  
                                    format='DD/MM/YYYY'
                                    dayOfWeekFormatter={(n,date) => number2dayweek[date.$W]}
                                    ampm={false}
                                />
                              
                                <FormControl size="small">
                                        <InputLabel id="labeltipo">Tipo</InputLabel>
                                            <Select
                                                labelId='labeltipo'
                                                id="type"
                                                label="Tipo"
                                                value={state.type}
                                                onChange={(e) => { setState({ 'type': e.target.value }) }}
                                                fullWidth={false}                                           
                                                >
                                                {selectOptions.map((option, index) => (
                                                    <MenuItem key={index} value={option}>{option}</MenuItem>
                                                ))}
                                            </Select>
                                </FormControl>


                            </Box>

                    </Box>

                        
                    </>
                }
                action={
                    <>
                        <Tooltip placement="left" title={validData}>
                            <Box>
                                <ColorButton
                                    onClick={() => submit(state, reloadData, setLoader, handleClose)}
                                    disabled={validData}
                                >Salvar
                                </ColorButton>
                            </Box>
                        </Tooltip>
                    </>
                }
            />
        </>
    )
}

export default EditFiles;