import React, { useState } from 'react'
import Box from '@mui/material/Box';
import CreateIcon from '@mui/icons-material/Create';
import { NumbersOnly, stringToInt } from '../Utils/IntegerOnly';
import toast from 'react-hot-toast';

const SendBox = ({quantity,row}) => {

    const [icon,setIcon] = useState(true)
    const [send,setSend] = useState(quantity)

    const changeToInput = () => {
        setIcon(false)
    }

    const handleChange = (event) => {
        const inputValue = event.target.value;
        const check_num = NumbersOnly(inputValue)
        

        if(check_num > quantity){
            toast.error(`A quantidade máxima é: ${quantity}`)
            setSend(quantity)
        }else{
            setSend(check_num)
            row['amount'] = stringToInt(check_num)
        }
      
    }

    const changeDict = () => {
        setIcon(true)

        if(send === '' || send === '0'){
            setSend(quantity)
        }
    }


    return(
        <>
        {
            icon ?
            <Box onClick={changeToInput} className='pencil-icon'>
                {send}
                <CreateIcon/>
            </Box>
            :
            <Box>
                <input className='input-inside' autoFocus={true}  value={send} onChange={handleChange} onBlur={changeDict} type="text"/>
            </Box>
        }   
        </>
    )
}

export default SendBox;