import React, { useContext, useLayoutEffect, useReducer, useRef } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { lockedReducer } from '../Utils/reducers'
import { api } from '../../services/api'
import { externalComponent } from '../AppRoutes'
import TableToolbar from './toolbar'
import MobileTable from './mobileTable'

const getData = async (url, state, updateRows = () => { }, setLoader, setState, customNameFilter='', customFilter='', rows = []) => {
    setLoader(true)

    const [reqURL,] = url.split("?")
    const searchParams = new URLSearchParams();

    //pagination
    searchParams.set('limit', state.paginationModel.pageSize)
    searchParams.set('offset', (state.paginationModel.page) * state.paginationModel.pageSize)

    if(customNameFilter !== ''){
        searchParams.set(customNameFilter, customFilter)
    }

    if (state.mobileFilter)
        searchParams.set('offset', 0)

    //filter
    Object.entries(state.filterModel.customItems).forEach(([key, value]) => {
        value && searchParams.set([key.replace('__equals', '')], value)
    })

    //or
    if (state.filterModel.or)
        searchParams.set('or', 'or')

    const finalURL = `${reqURL}?${searchParams.toString()}`
    const res = await api.get(finalURL)
    setLoader(false)
    const newState = {
        paginationModel: state.paginationModel,
        filterModel: { ...state.filterModel }
    }
    if (res.status === 200) {
        if (state.scroll)
            rows.push(...(res.data.results || res.data))
        else    
            updateRows(res.data.results || res.data)
        newState.rowCount = res.data.count || res.data.length
        newState.next = res.data.next || null
        newState.previous = res.data.previous || null
        newState.ready = true
        newState.getURL = finalURL
    } else {
        newState.ready = true
    }
    setState(newState)
}

export const TableHook = () => {
    const table = useRef()
    const reloadData = table.reloadData || (() => { })
    return [table, reloadData]
}

const initState = {
    paginationModel: { page: 0, pageSize: 25 },
    filterModel: { items: [], customItems: {} },
    next: null,
    rowCount: 0,
    previous: null,
    ready: false,
}

let lastRender = false

const Table = ({ getURL, updateRows, innerRef, toolbar, mobile, mobileProps, customNameFilter='', customFilter='', ...props  }) => {

    const { loader, setLoader, isMobile } = useContext(externalComponent)

    const apiRef = useRef()

    const [state, setState] = useReducer(lockedReducer, {
        ...initState,
        getURL: getURL
    })


    useLayoutEffect(() => {
        if ((!state.ready && !loader && getURL) || (lastRender !== isMobile)) {
            getData(getURL, initState, updateRows, setLoader, setState, customNameFilter, customFilter)
            lastRender=isMobile
        }
    }, [getURL, loader, setLoader, state.ready, updateRows, isMobile])

    if (innerRef) {
        innerRef.reloadData = (url, data = {}) => { getData(url || getURL, { ...state, ...data }, updateRows, setLoader, setState, customNameFilter, customFilter) }
        innerRef.apiRef = apiRef
        innerRef.state = state
    }

    const settoolbar = toolbar ? {
        slots: { ...props.slots, toolbar: TableToolbar }
    } : null

    return (
        <>
            {(state.ready && (!isMobile || !mobile)) &&
                <DataGrid
                    {...props}
                    {...state}
                    {...settoolbar}
                    pageSizeOptions={[25, 50, 100]}
                    paginationMode='server'
                    onPaginationModelChange={(e) => {
                        getData(state.getURL, { ...state, paginationModel: e }, updateRows, setLoader, setState, customNameFilter, customFilter)
                    }}
                    apiRef={apiRef}
                    filterMode='server'
                    onFilterModelChange={(e) => getData(state.getURL, { ...state, filterModel: e }, updateRows, setLoader, setState, customNameFilter, customFilter)}
                    updateTableState={(newstate) => setState(newstate)}
                    initialState={{
                        columns: {
                            columnVisibilityModel:
                                Object.fromEntries(props.columns.filter(e => e.hide === true).map(e => ([e.field, false])))
                        }
                    }}
                />}
                {(state.ready && (isMobile && mobile)) &&
                <MobileTable 
                    rows={props.rows}
                    parent={state}
                    setParent={setState}
                    onFilterModelChange={(e) => getData(state.getURL, { ...state, filterModel: e, mobileFilter:true }, updateRows, setLoader, setState, customNameFilter, customFilter)}
                    onPaginationModelChange={(e) => {
                        getData(state.getURL, { ...state, paginationModel: e, scroll: true }, updateRows, setLoader, setState, customNameFilter, customFilter, props.rows)
                    }}
                    columns={props.columns}
                    slots={props.slots}
                    getRowClassName={props.getRowClassName}
                    {...mobileProps}
                />}
        </>
    )
}

export default Table