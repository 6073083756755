import React, { useContext, useMemo, useReducer, useEffect } from 'react'
import { Box, IconButton } from '@mui/material'
import { api } from '../../../services/api';
import toast from 'react-hot-toast'
import { getSession } from '../../../contexts/auth';
import { lockedReducer } from '../../Utils/reducers';
import DefaultModal from '../../Utils/DefaultModal';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TableRowsIcon from '@mui/icons-material/TableRows';
import ConfirmationDialog from '../../Utils/ConfirmationDialog';
import { externalComponent } from '../../AppRoutes';
import Table, { TableHook } from '../../Table';
import EditFiles from './editfiles';
import ProdutoDocumento from './indexproducts';
import dayjs from 'dayjs';

const url = '/pedido_documento/'

const submitDelete = async (data, setState, getData, setLoader) => {
    setLoader(true)
    const res = await api.delete(`${url}${data.id}/`)
    setLoader(false)
    if (res.status === 204) {
        toast.success('Deletado com sucesso')
        setState({ deleteRow: false })
        getData()
        return
    }
    console.error('Falha ao deletar')

}

export const loadData = async (setState, permission, setLoader) => {
    if (!permission) {
        toast.error('Você não tem permissão para ver os Fretes')
        return
    }
}

const columns = (setState, permissions) => {
    return [
        {
            field: 'products', headerName: 'Produtos', filterable: false, flex:0.2,
            renderCell: (props) => {
                return (
                    <Box >
                        {permissions.change_orderfile && <IconButton onClick={() => { setState({ productRow: props.row }) }}><TableRowsIcon /></IconButton>}
                    </Box>
                )
            }
        },
        { field: 'name', headerName: 'Nome', flex: 0.2, filterType: 'string' },
        { field: 'number', headerName: 'Número', flex: 0.2, filterType: 'string' },
        { field: 'type', headerName: 'Tipo', flex: 0.2, filterType: 'string' },
        { field: 'total_amount', headerName: 'Total de Pneus', flex: 0.3, filterType: 'string' },
        { field: 'total_price', headerName: 'Total em Dólar', flex: 0.3, filterType: 'string' },
        {
            field: 'actions', headerName: 'Ações', filterable: false,
            renderCell: (props) => {
                return (
                    <Box >
                        {permissions.change_orderfile && <IconButton onClick={() => { setState({ editRow: props.row }) }}><EditIcon /></IconButton>}
                        {permissions.delete_orderfile && <IconButton onClick={() => { setState({ deleteRow: props.row }) }}><DeleteIcon /></IconButton>}
                    </Box>
                )
            }
        }
    ]
}

const PedidoDocumentos = ({data,handleClose}) => {

    const { setLoader } = useContext(externalComponent)

    const user = getSession()

    const [state, setState] = useReducer(lockedReducer, {
        rows: [],
        editRow: null,
        deleteRow: null,
        productRow: null,
        unitys: []
    })

    useEffect(() => {
        loadData(setState, user.permissions.view_orderfile, setLoader)
    }, [setLoader, user.permissions.view_orderfile])

    const [table, reloadTable] = TableHook()

    const reloadData = () => {
        loadData(setState, user.permissions.view_orderfile, setLoader)
        reloadTable()
    }

    return(
        <>
         {state.deleteRow &&
                <ConfirmationDialog
                    content={`Deseja excluir o documento ${state.deleteRow.identifier}?`}
                    handleClose={() => setState({ deleteRow: null })}
                    onConfirm={() => submitDelete(state.deleteRow, setState, reloadData, setLoader)}
                />
         }
        {state.editRow &&
                <EditFiles
                    orderId={data.id}
                    data={state.editRow}
                    handleClose={() => setState({ editRow: null })}
                    reloadData={reloadData}
                />
        }

        {state.productRow &&

            <ProdutoDocumento
            data={state.productRow}
            handleClose={() => setState({ productRow: null })}
            reloadData={reloadData}
            />

        }

        <DefaultModal
                title={
                    `Documentos`
                }
                dialogProps={{
                    sx:{'& .MuiPaper-root': {
                        maxWidth:'unset',
                        maxHeight:'unset',
                        width:'75%',
                        height:'75%',
                        margin:'0px'
                    }}
                }}
                  
                handleClose={handleClose}
                content={
                    <>
                    <Box className='information-table'>
                            <Box className='user-table'>
                                <Table
                                    rows={state.rows}
                                    columns={useMemo(()=> columns(setState, user.permissions), [user.permissions])}
                                    pagination
                                    density='compact'
                                    slots={{
                                        addComponent: () => user.permissions.add_orderfile ? setState({ editRow: {} }) : null,
                                        filterComponent: true,
                                        columnComponent: true
                                    }}
                                    toolbar
                                    getURL={`/pedido_documento/${data.id}/files/`}
                                    updateRows={(rows) => setState({'rows': rows})}
                                    innerRef={table}
                                />
                            </Box>
                        </Box>
                        </>
                }
                />
        </>
    )
}

export default PedidoDocumentos;