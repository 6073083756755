import React, { useReducer, useContext, useEffect } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { api } from '../../../services/api'
import ColorButton from '../../Buttons/ColorButton';
import DefaultModal from '../../Utils/DefaultModal';
import toast from 'react-hot-toast'
import { Box, Tooltip, TextField } from '@mui/material'
import validateData from './validateData';
import { externalComponent } from '../../AppRoutes';
import MegaAutoComplete from '../../Utils/megautocomplete';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { number2dayweek } from '../../Utils/dayjsptbrhelper';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';


const url = '/despesas_destino/'

const submit = async (data, getData, setLoader, handleClose) => {

    setLoader(true)
    let res = null

    if (data.id)
        res = await api.patch(`${url}${data.id}/`, data)
    else
        res = await api.post(url, data)
    if (res.status === 201 || res.status === 200) {
        toast.success(`Despesa Destino ${res.status === 201 ? 'adicionado' : 'editado'} com sucesso`)
        getData()
        handleClose()
        setLoader(false)
        return
    }
    toast.error(`Despesa Destino não pôde ser ${!data.id ? 'adicionado' : 'editado.'} Motivo: ${res.data}`)
    setLoader(false)
}

const loadRetrieve = async (id, setState, setLoader) => {
    setLoader(true)
    const res = await api.get(url + `${id}/`)
    if (res.status === 200) {
        setState({ ...res.data})
    }
    setLoader(false)
}

const getTitle = (data) => {
    if (data?.id)

        return 'Editar'
    return 'Adicionar'
}

const selectOptions = ["BL", "CTR"]

const EditDestinationExpenses = ( {data, handleClose, reloadData} ) => {

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        armador: '',
        pod: '',
        start_date: '',
        end_date: '',
        service_expense: '',
        charge: '',
        value: '',
        coin: '',
        id: data.id ? data.id : 0
    })

    const validData = validateData(state)

    useEffect(() => {
        if (data.id)
            loadRetrieve(data.id, setState, setLoader)
        else
            setState({ loaded: true })
    }, [setLoader, data.id])
    
    return(
        <>
        <DefaultModal
                title={
                    `${getTitle(state)} Despesa Destino`
                }
                handleClose={handleClose}
                content={
                    <>
                        <Box className='item-content-modal noborder' >

                            <Box className='item-profile-row'>

                                <MegaAutoComplete
                                        value={state.armador}
                                        onChange={(data) => setState({ armador : data })}
                                        noOptionsText={'Digite para procurar um Armador'}
                                        timeoutUrl='/armador/complete/'
                                        timeoutParam='label'
                                        label='Armador'
                                />

                                <TextField
                                    id="pod"
                                    type="text"
                                    label="POD"
                                    value={state.pod}
                                    onChange={(e) => { setState({ 'pod': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                            </Box>

                            <Box className='item-profile-row'>
                                    
                                <DatePicker 
                                slotProps={{ textField: { size: 'small' } }} 
                                label="Data Inicial:"
                                size="small"
                                value={dayjs(state.start_date)}  
                                onChange={(e) => { setState({ 'start_date': e.format('YYYY-MM-DD') }) }}  
                                format='DD/MM/YYYY'
                                dayOfWeekFormatter={(n,date) => number2dayweek[date.$W]}
                                ampm={false}
                                />
                        

                                <DatePicker 
                                slotProps={{ textField: { size: 'small' } }} 
                                label="Data Final:"
                                size="small"
                                value={dayjs(state.end_date)}
                                onChange={(e) => { setState({ 'end_date': e.format('YYYY-MM-DD') }) }}  
                                format='DD/MM/YYYY'
                                dayOfWeekFormatter={(n,date) => number2dayweek[date.$W]}
                                ampm={false}
                                />
                              
                            </Box>

                            <Box className='item-profile-row'>

                                <TextField
                                        id="service_expense"
                                        type="text"
                                        label="Despesa/Serviço"
                                        value={state.service_expense}
                                        onChange={(e) => { setState({ 'service_expense': e.target.value }) }}
                                        fullWidth={false}
                                        size='small'
                                        inputProps={{
                                            maxLength: 50
                                        }}
                                    />

                                <FormControl size='small'>
                                    <InputLabel id="labelSeguradora">Cobrança</InputLabel>
                                        <Select
                                            labelId='labelSeguradora'
                                            id="charge"
                                            label="Cobrança"
                                            value={state.charge}
                                            onChange={(e) => { setState({ 'charge': e.target.value }) }}
                                            fullWidth={false}
                                            
                                            >
                                            {selectOptions.map((option, index) => (
                                                <MenuItem key={index} value={option}>{option}</MenuItem>
                                            ))}
                                        </Select>
                                </FormControl>

                            </Box>

                            <Box className='item-profile-row'>

                                <TextField
                                    id="value"
                                    type="text"
                                    label="Valor"
                                    value={state.value}
                                    onChange={(e) => { setState({ 'value': parseInt(e.target.value) }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                                <TextField
                                    id="coin"
                                    type="text"
                                    label="Moeda"
                                    value={state.coin}
                                    onChange={(e) => { setState({ 'coin': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                            </Box>

                        </Box>

                        
                    </>
                }
                action={
                    <>
                        <Tooltip placement="left" title={validData}>
                            <Box>
                                <ColorButton
                                    onClick={() => submit(state, reloadData, setLoader, handleClose)}
                                    disabled={validData}
                                >Salvar
                                </ColorButton>
                            </Box>
                        </Tooltip>
                    </>
                }
            />
        </>
    )
}

export default EditDestinationExpenses;