import React, { useContext, useMemo, useReducer, useEffect } from 'react'
import { Box, IconButton } from '@mui/material'
import { api } from '../../services/api';
import toast from 'react-hot-toast'
import { getSession } from '../../contexts/auth';
import { lockedReducer } from '../Utils/reducers';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationDialog from '../Utils/ConfirmationDialog';
import { externalComponent } from '../AppRoutes';
import Table, { TableHook } from '../Table';
import EditPreBoarding from './editPreBoarding';

const url = '/importacao/'

const submitDelete = async (data, setState, getData, setLoader) => {
    setLoader(true)
    const res = await api.delete(`${url}${data.id}/`)
    setLoader(false)
    if (res.status === 204) {
        toast.success('Deletado com sucesso')
        setState({ deleteRow: false })
        getData()
        return
    }
    console.error('Falha ao deletar')

}

export const loadData = async (setState, permission, setLoader) => {
   
    if (!permission) {
        toast.error('Você não tem permissão para ver os Pré-Embarques')
        return
    }
  
}

export const defaultChoices = {
    1: 'Status 1',
    2: 'Status 2',
    3: 'Status 3'
}

const columns = (setState, permissions) => {
    return [
        { field: 'oid', headerName: 'Número da Importação', flex: 0.6, filterType: 'number' },
        { field: 'status', headerName: 'Status', flex: 0.2, filterType: 'choices', choices:defaultChoices,
            valueGetter: ({row}) => defaultChoices[row.status]
        },
        { field: 'num_pi', headerName: 'Número PI', flex: 2, filterType: 'string' },
        {
            field: 'actions', headerName: 'Ações', filterable: false,
            renderCell: (props) => {
                return (
                    <Box >
                        {permissions.change_preboarding && <IconButton onClick={() => { setState({ editRow: props.row }) }}><EditIcon /></IconButton>}
                        {permissions.delete_preboarding && <IconButton onClick={() => { setState({ deleteRow: props.row }) }}><DeleteIcon /></IconButton>}
                    </Box>
                )
            }
        }
    ]
}

const PreBoarding = () => {

    const { setLoader } = useContext(externalComponent)

    const user = getSession()

    const [state, setState] = useReducer(lockedReducer, {
        rows: [],
        editRow: null,
        deleteRow: null,
        unitys: []
    })

    useEffect(() => {
        loadData(setState, user.permissions.view_preboarding, setLoader)
    }, [setLoader, user.permissions.importacao])

    const [table, reloadTable] = TableHook()

    const reloadData = () => {
        loadData(setState, user.permissions.view_preboarding, setLoader)
        reloadTable()
    }

    return(
        <>
         {state.deleteRow &&
                <ConfirmationDialog
                    content={`Deseja excluir o Pré-Embarque ${state.deleteRow.identifier}?`}
                    handleClose={() => setState({ deleteRow: null })}
                    onConfirm={() => submitDelete(state.deleteRow, setState, reloadData, setLoader)}
                />
         }
        {state.editRow &&
                <EditPreBoarding
                    data={state.editRow}
                    handleClose={() => setState({ editRow: null })}
                    reloadData={reloadData}
                />
        }

         <Box className='information-table'>
                <Box className='user-table'>
                    <Table
                        rows={state.rows}
                        columns={useMemo(()=> columns(setState, user.permissions), [user.permissions])}
                        pagination
                        density='compact'
                        slots={{
                            filterComponent: true,
                            columnComponent: true
                        }}
                        toolbar
                        getURL={url}
                        customNameFilter='status'
                        customFilter={2}
                        updateRows={(rows) => setState({'rows': rows})}
                        innerRef={table}
                    />
                </Box>
            </Box>
        </>
    )
}

export default PreBoarding;