import React, { useReducer, useContext, useEffect } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { api } from '../../../services/api'
import ColorButton from '../../Buttons/ColorButton';
import DefaultModal from '../../Utils/DefaultModal';
import toast from 'react-hot-toast'
import { Box, Tooltip, TextField } from '@mui/material'
import validateData from './validateData';
import { externalComponent } from '../../AppRoutes';
import MegaAutoComplete from '../../Utils/megautocomplete';

const url = '/exportador/'

const submit = async (data, getData, setLoader, handleClose) => {

    setLoader(true)
    let res = null

    if (data.id)
        res = await api.patch(`${url}${data.id}/`, data)
    else
        res = await api.post(url, data)
    if (res.status === 201 || res.status === 200) {
        toast.success(`Exportador ${res.status === 201 ? 'adicionado' : 'editado'} com sucesso`)
        getData()
        handleClose()
        setLoader(false)
        return
    }
    toast.error(`Exportador não pôde ser ${!data.id ? 'adicionado' : 'editado.'} Motivo: ${res.data}`)
    setLoader(false)
}

const loadRetrieve = async (id, setState, setLoader) => {
    setLoader(true)
    const res = await api.get(url + `${id}/`)
    if (res.status === 200) {
        setState({ ...res.data})
    }
    setLoader(false)
}

const getTitle = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const EditExporter = ({data, handleClose, reloadData}) => {

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        name: '',
        phone_number: '',
        email: '',
        street: '',
        street_number: '',
        complement: '',
        city: '',
        state: '',
        country: '',
        cep: '',
        ...data,
        id: data.id ? data.id : 0
    })

    const validData = validateData(state)

    useEffect(() => {
        if (data.id)
            loadRetrieve(data.id, setState, setLoader)
        else
            setState({ loaded: true })
    }, [setLoader, data.id])

    return(
        <>
        <DefaultModal
                title={
                    `${getTitle(state)} Exportador`
                }
                handleClose={handleClose}
                content={
                    <>
                        <Box className='item-content-modal noborder' >
                            <Box className='item-profile-row'>
                                <TextField
                                    id="name"
                                    type="text"
                                    label="Nome"
                                    value={state.name}
                                    onChange={(e) => { setState({ 'name': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                                <TextField
                                    id="phone_number"
                                    type="text"
                                    label="Telefone"
                                    value={state.phone_number}
                                    onChange={(e) => { setState({ 'phone_number': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                    maxLength: 50
                                    }}
                                />

                            </Box>

                            <Box className='item-profile-row'>

                                <TextField
                                    id="email"
                                    type="text"
                                    label="Email"
                                    value={state.email}
                                    onChange={(e) => { setState({ 'email': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                            </Box>

                            <Box className='item-profile-row'>

                                <TextField
                                        id="cep"
                                        type="text"
                                        label="CEP"
                                        value={state.cep}
                                        onChange={(e) => { setState({ 'cep': e.target.value }) }}
                                        fullWidth={false}
                                        size='small'
                                        inputProps={{
                                            maxLength: 50
                                        }}
                                />

                                <MegaAutoComplete
                                    value={state.country}
                                    onChange={(data) => setState({ country : data })}
                                    noOptionsText={'Digite para procurar um país'}
                                    timeoutUrl='/porto/countries/'
                                    timeoutParam='label'
                                    label='País'
                                />

                            
                            </Box>

                            <Box className='item-profile-row'>

                                <TextField
                                    id="street"
                                    type="text"
                                    label="Rua"
                                    value={state.street}
                                    onChange={(e) => { setState({ 'street': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                                <TextField
                                    id="street_number"
                                    type="text"
                                    label="Número"
                                    value={state.street_number}
                                    onChange={(e) => { setState({ 'street_number': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                            </Box>

                            <Box className='item-profile-row'>

                                <TextField
                                    id="city"
                                    type="text"
                                    label="Cidade"
                                    value={state.city}
                                    onChange={(e) => { setState({ 'city': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                                <TextField
                                    id="state"
                                    type="text"
                                    label="Estado"
                                    value={state.state}
                                    onChange={(e) => { setState({ 'state': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />

                            </Box>
                    
                            

                            <Box className='item-profile-row'>
                                <TextField
                                    id="complement"
                                    type="text"
                                    label="Complemento"
                                    value={state.complement}
                                    onChange={(e) => { setState({ 'complement': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    multiline
                                    rows={4}
                                    inputProps={{
                                        maxLength: 255
                                    }}
                                />

                            </Box>
                            
                        </Box>
                    </>
                }
                action={
                    <>
                        <Tooltip placement="left" title={validData}>
                            <Box>
                                <ColorButton
                                    onClick={() => submit(state, reloadData, setLoader, handleClose)}
                                    disabled={validData}
                                >Salvar
                                </ColorButton>
                            </Box>
                        </Tooltip>
                    </>
                }
            />
        </>
    )
}

export default EditExporter;