import { Box } from "@mui/material"

const validateData = (data) => {

    const response = []

    switch (data?.step){
        case 0:
            
            if (!data?.exporter)
                response.push(<Box>Selecione um Exportador.</Box>)
        
            if (!data?.fabricator)
                response.push(<Box>Selecione um Fabricante.</Box>)
        
            if (!data?.num_pi?.length > 0)
                response.push(<Box>Digite o número PI.</Box>)
        
            if (!data?.etd)
                response.push(<Box>Digite o ETD.</Box>)
        break
        case 1:

            if (!data?.shippingagent?.id)
                response.push(<Box>Selecione um Agente Marítimo.</Box>)
            
            if (!data?.pol?.id)
                response.push(<Box>Selecione um POL.</Box>)
        
            if (!data?.pod?.id)
                response.push(<Box>Selecione um POD.</Box>)
        
            if (!data?.armador?.id)
                response.push(<Box>Selecione um Armador.</Box>)
        
            if (!data?.ets)
                response.push(<Box>Digite a ETS.</Box>)
        
            if (!data?.eta)
                response.push(<Box>Digite a ETA.</Box>)
        
            if (!data?.frete > 0)
                response.push(<Box>Digite o Frete.</Box>)

        break
        default:
            break
    }


    if (response.length === 0)
        return ''

    return (response)
}

export default validateData