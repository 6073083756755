import React, { useReducer, useContext, useEffect } from 'react'
import { lockedReducer } from '../Utils/reducers';
import ColorButton from '../Buttons/ColorButton';
import { externalComponent } from '../AppRoutes';
import { Box, Tooltip } from '@mui/material'
import DefaultModal from '../Utils/DefaultModal';
import { DataGrid } from '@mui/x-data-grid';
import clsx from 'clsx';
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import toast from 'react-hot-toast';
import { api } from '../../services/api';
import SendBox from './sendBox';

const url = '/importacao/'

const moveProduct = (table,setState,state) => {

    let newListA = null
    let newListB = null


    if(table === 'A'){
        const toMoveA = state.listB.filter(each => each.checked === true)
    
        newListB = state.listB.filter(itemB => {
            return !toMoveA.some(itemA => itemA.product === itemB.product);
        });
          
          
        newListA = [...state.listA,...toMoveA].sort((a, b) => a.description.localeCompare(b.description))
        setState({listA:newListA,listB:newListB})

    }else if(table === 'B'){

        const toMoveB = state.listA.filter(each => each.checked === true)

        newListA = state.listA.filter(itemA => {
            return !toMoveB.some(itemB => itemB.product === itemA.product);
        });


        newListB = [...state.listB,...toMoveB].sort((a, b) => a.description.localeCompare(b.description));
        setState({listA:newListA,listB:newListB})
       
    }

}

const saveProduct = async (state, setLoader, data, importacao) => {
    const body = {
        id_importacao: importacao.id,
        orderfile: data.id,
        invoice: state.listB
    }

    setLoader(true)
    const res = await api.post(`${url}save_file/`,body)
    setLoader(false)
    if (res.status === 200) {
        toast.success('Salvo com sucesso')
        return
    }

}

const ModalProduct = ({data, handleClose, importacao}) => {

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        listA: [],
        listB: []
    })

  

    useEffect(() => {
        
        const newList = [...data.productfile_set]
        newList.map(each => each['checked'] = false)
        const list = newList.sort((a, b) => a.description.localeCompare(b.description));

        if(data.type === 'PI'){
            setState({listB:list})

        } else if (data.type === 'PI Mãe') {
            setState({listA:list})
        }

            
    }, [])

    const columnsMain = [
    
        {
            field: 'product',
            headerName: 'Codpro',
            flex: 0.1,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            }

        },
        {
            field: 'description',
            headerName: 'Descrição',
            flex: 0.3,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            }

        },
        {
            field: 'filename',
            headerName: 'Documento',
            flex: 0.2,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            }

        },
        {
            field: 'amount',
            headerName: 'Quantidade',
            flex: 0.1,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            },
            renderCell: (params) => ( 
                <Box>
                    <SendBox quantity={params.value} row={params.row}/>
                </Box>
            ),
        },
        {
            field: 'price',
            headerName: 'Preço',
            flex: 0.1,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            }

        },
        {
            field: 'checkbox',
            headerName: '',
            flex: 0.1,
            renderCell: (props) => {
                return (
                    <Box>
                        <Checkbox checked={props.row.checked} onClick={() => props.row.checked = !props.row.checked} />
                    </Box>
                )
            }

        },


    ]


    return(
        <>
        <DefaultModal
                title={
                    `Produtos`
                }
                dialogProps={{
                    sx:{'& .MuiPaper-root': {
                        maxWidth:'unset',
                        maxHeight:'unset',
                        width: '95%',
                        height:'95%',
                        margin:'0px'
                    }}
                }}
                handleClose={handleClose}
                content={
                    <>

                    <Box sx={{display:'flex', flexDirection:'row', gap:'1rem', justifyContent:'center',marginBottom:'1rem'}}>
                        <ColorButton onClick={() => moveProduct('A',setState,state)}><KeyboardArrowLeftIcon/></ColorButton>
                        <ColorButton onClick={() => moveProduct('B',setState,state)}><KeyboardArrowRightIcon/></ColorButton>
                    </Box>

                    <Box sx={{display:'flex', flexDirection:'row', gap:'1rem'}}>

                        <Box sx={{display:'flex',width:'100%',height:'30rem'}} > 
                            <DataGrid
                                sx={{
                                    '& .default-app.selected': {
                                        justifyContent:'center',
                                        alignItems:'center',
                                    },
                                    height:"100%",
                                    '& .MuiDataGrid-columnHeaderTitleContainer': {
                                        justifyContent: 'center',
                                    },
                                    '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                                        color:'black'
                                    }
                                
                                }}
                                className='alterned_grid'
                                rows={state.listA}                                                                                                                                                                                                                                                                                                                                                                                                                       
                                columns={columnsMain}
                                pagination={false}
                                density='compact'
                                pageSize={5}
                                rowHeight={27}
                            />
                        </Box>
                        
                        <Box sx={{display:'flex',width:'100%',height:'30rem'}} > 
                            <DataGrid
                                sx={{
                                    '& .default-app.selected': {
                                        justifyContent:'center',
                                        alignItems:'center',
                                    },
                                    height:"100%",
                                    '& .MuiDataGrid-columnHeaderTitleContainer': {
                                        justifyContent: 'center',
                                      },
                                    '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                                        color:'black'
                                    }
                                  
                                }}
                                className='alterned_grid'
                                rows={state.listB}                                                                                                                                                                                                                                                                                                                                                                                                                       
                                columns={columnsMain}
                                pagination={false}
                                density='compact'
                                pageSize={5}
                                rowHeight={27}
                            />
                        </Box>

                    </Box>
                       
                    </>
                }
                action={
                    <>
                        <Tooltip placement="left" >
                            <Box>
                                <ColorButton
                                onClick={() => {saveProduct(state, setLoader, data, importacao)}}
                                >Salvar
                                </ColorButton>
                            </Box>
                        </Tooltip>
                    </>
                }
            />
        </>
    )
}

export default ModalProduct;